import React, { useEffect } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const Kosztorys = () => {
    const [isPapa, setIsPapa] = React.useState(false);
    const [isDachowka, setIsDachowka] = React.useState(false);
    const [isBlacha, setIsBlacha] = React.useState(false);
    const [price, setPrice] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const [sum, setSum] = React.useState(0);
    const [isStyropian, setIsStyropian] = React.useState(false);

    function selectBox(val) {
        if (val === 1) {
            setIsPapa(!isPapa)
            setIsDachowka(false)
            setIsBlacha(false)
            setPrice(15)
        }
        if (val === 2) {
            setIsDachowka(!isDachowka)
            setIsPapa(false)
            setIsBlacha(false)
            setPrice(60)
        }
        if (val === 3) {
            setIsBlacha(!isBlacha)
            setIsDachowka(false)
            setIsPapa(false)
            setPrice(40)
        }
    }

    function handleChange(event) {
        let tmpValue = event.target.value;
        if (!tmpValue) {
            setValue()
        } else {
            setValue(tmpValue)
        }
    }

    useEffect(() => {
        if(value !== undefined){
            let cost = value * price;
            if(isStyropian)
                cost = cost + (15*value);
            setSum(cost)
        }else{
            setSum(0);
        }
    }, [value, price, isStyropian]);

    return (
        <Layout pageInfo={{ pageName: "kosztorys" }}>
            <SEO title="Kosztorys" />
            <Container fluid className="info-bar p-4">
                <div>
                    <b>SKONTAKTUJ SIĘ Z NAMI - PRZYGOTUJEMY WYCENĘ TWOJEGO DACHU</b>
                </div>
            </Container>
            <Container>
                <div className="divider">Szacowany kosztorys</div>
                {/* <Row className="justify-content-center"> */}
                <Row>
                    <Col sm={6} className="p-0">
                        <Row>
                            <Col xs={12}>
                                <div className={(isPapa ? 'pricingBoxSelected' : 'pricingBox')} onClick={() => selectBox(1)}>Papa</div>
                                <div className={(isDachowka ? 'pricingBoxSelected' : 'pricingBox')} onClick={() => selectBox(2)}>Dachówka</div>
                                <div className={(isBlacha ? 'pricingBoxSelected' : 'pricingBox')} onClick={() => selectBox(3)}>Blachodachówka</div>
                                {(isPapa || isDachowka || isBlacha) && 
                                <div>
                                    <label>Łączna powierzchnia dachu:</label>
                                    <label className="sr-only" htmlFor="inlineFormInputGroup">powierzchnia dachu</label>
                                    <div className="input-group mb-2">
                                      <input type="text" className="form-control" type="number" value={Number(value).toString()}  onChange={handleChange} id="inlineFormInputGroup" placeholder="powierzchnia dachu"/>
                                      <div className="input-group-append">
                                        <div className="input-group-text">m<sup>2</sup></div>
                                      </div>
                                    </div>
                                </div>
                                }
                                
                                {(value !== undefined && value > 0) && 
                                <div className="mt-4">
                                    <label>Ocieplenie dachu:</label>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="option1" checked={isStyropian} onChange={() => setIsStyropian(!isStyropian)}/>Tak
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="option2" checked={!isStyropian} onChange={() => setIsStyropian(!isStyropian)}/>Nie
                                        </label>
                                    </div>
                                </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <Col xs={12}>
                                <div className="resultBox">
                                    <h5 className="table-title">Kosztorys</h5>
                                    <div>Razem netto: {sum} zł</div>
                                    <div>Razem z 8% VAT: {(sum * 1.08).toFixed()} zł</div>
                                    <div>Razem z 23% VAT: {(sum * 1.23).toFixed()} zł</div>
                                    <div className="table-info">
                                        Nasze wyliczenia mają charakter informacyjny i nie stanowią oferty w rozumieniu Kodeksu Cywilnego.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Kosztorys